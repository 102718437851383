export const getParams = (key) => {
    const params = new URLSearchParams(document.location.search);
    if (params) {
        return params.get(key);
    }
}

export const checkIsBase64 =(data) => {
    // try {
    //     // Normalize the Base64 string by adding missing padding
    //     const normalizedStr = data.replace(/-/g, '+').replace(/_/g, '/');
    //     const paddingNeeded = (4 - (normalizedStr.length % 4)) % 4;
    //     const paddedStr = normalizedStr + '='.repeat(paddingNeeded);

    //     // Decode and re-encode to verify
    //     const decoded = atob(paddedStr);
    //     const reEncoded = btoa(decoded).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

    //     return reEncoded === data;
    // } catch (error) {
    //     return false;
    // }
    if (typeof data !== 'string') {
        return false;
    }

    try {
        // Normalize Base64 (handle URL-safe variants)
        const normalizedStr = data.replace(/-/g, '+').replace(/_/g, '/');
        
        // Check padding: Add "=" to ensure proper Base64 length (multiple of 4)
        const paddingNeeded = (4 - (normalizedStr.length % 4)) % 4;
        const paddedStr = normalizedStr + '='.repeat(paddingNeeded);

        // For Node.js: Use Buffer.from (if in Node)
        if (typeof window === 'undefined') {
            const decoded = Buffer.from(paddedStr, 'base64').toString('utf-8');
            return decoded.length > 0;
        } else {
            // For ReactJS/Browser: Use atob() (browser-specific)
            const decoded = atob(paddedStr);
            return decoded.length > 0;
        }
    } catch (error) {
        return false;
    }
   
}

export const decodeBase64 = (encodedData) => {
    // const decodedString = atob(encodedData);
    // return JSON.parse(decodedString);
     // Convert Base64 URL encoding to standard Base64
     let base64 = encodedData.replace(/-/g, '+').replace(/_/g, '/');

     // Ensure correct padding
     while (base64.length % 4 !== 0) {
         base64 += '=';
     }
 
     // Decode the Base64 string
     const decodedString = atob(base64);
 
     // Parse as JSON
     return JSON.parse(decodedString);
}

export const formatPhoneNumber = (phoneNumber) => {
    // Get last two digits
    var lastTwoDigits = phoneNumber.slice(-2);
    // Split the string into pairs of two digits
    var splitnumber = phoneNumber.match(/.{1,2}/g).join(' ');
    // Replace digits with asterisks except the last two
    var formattedNumber = splitnumber.slice(0, -2).replace(/\d/g, '*') + lastTwoDigits;

    return formattedNumber;
}

export const formatEmail = (email) => {
    // Split email address into username and domain parts
    var parts = email.split('@');
    if (parts.length !== 2) {
        return "Invalid email address";
    }
    // Hide characters in the username, leaving only the first three characters visible
    var hiddenUsername = parts[0].substring(0, 3) + '*'.repeat(parts[0].length - 3);

    // Return the hidden email address with a placeholder domain
    return hiddenUsername + '@' + parts[1];
}