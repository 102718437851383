import React from "react";
import { Link } from "react-router-dom";

const CodeVerification = ({ codes = [], onSubmit, onClick, inputRefs, onChange, onPaste, onKeyDown }) => (
    <>
        <div className="text-center trouble_wrap">
            <p>
                Haven't received a PIN code? <Link to="#" onClick={onClick}>Get a new code</Link>
            </p>
        </div>
        <form onSubmit={onSubmit} className="code_wrap">
            <p className="text-center">Enter verification code*:</p>
            <div className="form-group verifycode_wrap d-flex mb-1">
                {codes.slice(0, 4).map((value, index) => (
                    <input
                        key={`code-input-${index}`} // Unique key
                        className={"form-control single_field gap"}
                        type="tel"
                        maxLength="1"
                        value={value}
                        onChange={(e) => onChange(index, e)} // Fix handler syntax
                        onPaste={onPaste}
                        ref={inputRefs[index] || (inputRefs[index] = React.createRef())}
                        onKeyDown={(e) => onKeyDown(index, e)} // Fix handler syntax
                    />
                ))}
            </div>
            <div className="text-center pt-3">
                <button type="submit" className="btn btn-primary login-btn">Verify</button>
            </div>
            <div className="text-center trouble_wrap pt-3">
                <p>
                    Having trouble with the verification code? <a href="mailto:contact@theloginhub.eu">Contact Us</a>
                </p>
            </div>
        </form>
    </>
);

export default CodeVerification;
